import React, { useEffect, useState } from "react";
import {
  FaReact,
  FaHtml5,
  FaCss3,
  FaBootstrap,
  FaNodeJs,
  FaPhp,
  FaPython,
} from "react-icons/fa"; // Import icons you need
import { MdJavascript } from "react-icons/md";
import { SiTailwindcss, SiMongodb, SiMysql } from "react-icons/si";
import "./index.css";
import { BsFillPatchCheckFill } from "react-icons/bs";

const experiences = [
  {
    category: "Frontend Development",
    details: [
      { skill: "HTML", level: "Experienced" },
      { skill: "CSS", level: "Experienced" },
      { skill: "JavaScript", level: "Experienced" },
      { skill: "Bootstrap", level: "Experienced" },
      { skill: "Tailwind", level: "Experienced" },
      { skill: "React", level: "Experienced" },
    ],
  },
  {
    category: "Backend Development",
    details: [
      { skill: "Node Js", level: "Experienced" },
      { skill: "PHP", level: "Experienced" },
      { skill: "MongoDB", level: "Experienced" },
      { skill: "MYSQL", level: "Experienced" },
      { skill: "Python", level: "Experienced" },
      { skill: "Meta", level: "Experienced" },
    ],
  },
];

const colors = [
  "#e74c3c",
  "#3498db",
  "#f1c40f",
  "#2ecc71",
  "#9b59b6",
  "#34495e",
  "#e67e22",
  "#1abc9c",
]; // Add more colors as needed

const iconComponents = [
  FaCss3,
  FaHtml5,
  FaReact,
  FaBootstrap,
  SiTailwindcss,
  MdJavascript,
  FaNodeJs,
  SiMongodb,
  FaPhp,
  SiMysql,
  FaPython,
];

const FloatingIcon = ({ IconComponent, size, duration, left, color }) => {
  const style = {
    fontSize: size,
    color: color, // Apply the color
    animationDuration: `${duration}s`,
    left: `${left}%`,
  };

  return (
    <div className="floating-icon" style={style}>
      <IconComponent />
    </div>
  );
};

const Experience = ({ count = 20 }) => {
  const [floatingIcons, setFloatingIcons] = useState([]);

  useEffect(() => {
    setFloatingIcons(
      Array.from({ length: count }).map(() => ({
        IconComponent:
          iconComponents[Math.floor(Math.random() * iconComponents.length)],
        color: colors[Math.floor(Math.random() * colors.length)], // Randomly pick a color
        size: `${Math.random() * 2 + 1.5}rem`, // Random size between 1.5rem and 3.5rem
        duration: Math.random() * 5 + 5, // Random animation duration between 5s and 10s
        left: Math.random() * 100, // Random horizontal position
      }))
    );
  }, [count]);

  return (
    <section id="experience" className="bg-blue-950">
      <div className="floating-icons-container flex flex-col justify-center items-center relative">
        <h2 className="text-center text-[30px] md:text-[36px] lg:text-[40px] font-poppins font-bold text-cyan-500 mt-12">
          Services
        </h2>
        {floatingIcons.map((props, index) => (
          <FloatingIcon key={index} {...props} />
        ))}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 md:gap-8 lg:gap-10 mt-10 w-full px-4 lg:px-16 pb-16">
          {experiences.map((experience, index) => (
            <div
              key={index}
              className="bg-sky-600 p-6 lg:p-8 rounded-md shadow-md mx-auto w-full max-w-lg lg:max-w-xl"
            >
              <h3 className="text-xl lg:text-2xl font-bold mb-6 text-center text-white">
                {experience.category}
              </h3>
              <div className="flex flex-wrap justify-center gap-4">
                {experience.details.map((detail, detailIndex) => (
                  <article
                    key={detailIndex}
                    className="flex items-center gap-2 w-28 lg:w-32 justify-center"
                  >
                    <BsFillPatchCheckFill className="text-white" size={18} />
                    <div className="text-center">
                      <h4 className="text-white font-semibold text-sm lg:text-md">
                        {detail.skill}
                      </h4>
                      <small className="text-white text-xs">
                        {detail.level}
                      </small>
                    </div>
                  </article>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;
