import React from "react";
// import "./Awards.css";
import Global from "../details/images/global.png";
import Android from "../details/images/android.png";
import UiUx from "../details/images/uiux.png";
import Apple from "../details/images/Apple.png";

const AWARD = [
  {
    id: 1,
    icon: Global,
    title: "Ranked Top 3% On Leading Freelance Platform Upwork",
    describe: "2023",
  },
  {
    id: 2,
    icon: UiUx,
    title: "100% Job Satisfaction Record on Upwork",
    describe: "2020",
  },
  {
    id: 3,
    icon: Android,
    title: "UI UX Innovation Expert Developers",
    describe: "2019-2023",
  },
  {
    id: 4,
    icon: Apple,
    title: "Top App & Web Development Company",
    describe: "2022",
  },
];

function Awards() {
  return (
    <div className="flex flex-wrap sm:max-w-lg justify-center items-center">
      {AWARD.map(({ icon, title, describe }, index) => (
        <div
          key={title}
          className={`mb-4 text-center max-w-xl ${
            index !== AWARD.length - 1 ? "mr-2" : "mr-0"
          }`}
        >
          <div className="w-6 h-6 overflow-hidden rounded-full mx-auto mb-2">
            <img
              src={icon}
              alt={title}
              className="w-full h-full object-cover"
            />
          </div>
          <h5 className="text-yellow-200 font-bold text-[11px] w-[115px]">
            {title}
          </h5>
          {/* <small className="text-yellow-200 text-[9px]">{describe}</small> */}
        </div>
      ))}
    </div>
  );
}

export default Awards;
