import React from "react";

function Footer({ backgroundColor, color }) {
  return (
    <section className="flex justify-center" style={{ backgroundColor, color }}>
      <div className="w-[90%] flex flex-col justify-between items-center text-center md:flex-row pt-3 sm:pt-6 mt-2 pb-2 px-2 border-t-[1px] border-t-[#3f3r45]">
        <p className="font-poppins font-medium text-center text-[9px] sm:text-[13px] leading-[27px] ">
          Cosmolink Tech Solutions
        </p>
        <p className="font-poppins font-medium text-center text-[9px] sm:text-[13px] sm:leading-[27px] ">
          Built with ❤️ in India
        </p>
      </div>
    </section>
  );
}

export default Footer;
