import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosStar } from "react-icons/io";
import { motion, useInView } from "framer-motion";
import "./index.css";

const projectsData = [
  {
    id: "themorningcontext",
    title: "The Morning Context",
    description:
      "The Morning Context is a media and research company that tells the most ambitious, timely, deeply researched, and well-written stories.",
    backgroundColor: "#9F84E3",
    imageSrc: require("./images/tmc.png"),
    name: "Priya Bubna",
    profile: require("../../portfolio/projects/images/Priya.jpg"),
    role: "CEO",
    comment:
      "Team at Cosmolink not only addressed all our app issues but also introduced innovative features, making it better than before. Highly satisfied!",
  },
  {
    id: "chance",
    title: "Chance",
    description:
      "Chance is an intelligent personality-based, pseudonymous connecting and dating app that matches you with people based on personality and interests.",
    backgroundColor: "#1e7e93",
    imageSrc: require("./images/chance.png"),
    name: "Rajeet Parekh",
    profile: require("../../portfolio/projects/images/chance.jpeg"),
    role: "Founder @ Chance",
    rating: 5,
    comment: "Delivered really good and quality work. Thanks!!",
  },
  {
    id: "babysense",
    title: "Babysense",
    description:
      "A smart way to monitor and keep your baby safe - Babysense Baby Smart Nursery Monitoring solution provides a modular eco-system allowing you to track breathing movements & sleep patterns.",
    backgroundColor: "#fdadaa",
    imageSrc: require("./images/babysence.png"),
    name: "Itsik B.",
    profile: require("../../portfolio/projects/images/Itsik.jpeg"),
    role: "CTO",
    rating: 5,
    comment:
      "He did the job really well. Super talented and fast. Will work with him again. Thanks",
  },
];

const Projects = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.2, triggerOnce: true });
  const [isMobile, setIsMobile] = useState(false);

  // Check if the user is on mobile device
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Consider mobile for screen width <= 1024px
    };

    handleResize(); // Check on component mount
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on component unmount
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      id="section-1"
      className="projects-featured-outer px-2 md:px-4 lg:px-0 mb-4 lg:mb-0"
    >
      {/* Conditional styling for mobile devices */}
      <div className="lg:hidden text-center sm:text-left">
        <h2 className="font-poppins font-bold text-4xl sm:text-6xl md:text-7xl lg:text-8xl leading-tight text-black mt-8 mb-12">
          INNOVATIVE APP AND <br /> WEB SOLUTIONS
        </h2>

        {/* For mobile, just use a normal div */}
        {isMobile ? (
          <div className="flex flex-col sm:flex-row items-center justify-center gap-6">
            <p className="text-xl sm:text-2xl lg:text-3xl text-black">
              THOUGHTFULLY CRAFTED WEBSITES & APPS
            </p>
            <p className="text-xl sm:text-2xl lg:text-3xl text-black">
              BUILT FOR THE LONG HAUL
            </p>
          </div>
        ) : (
          // For larger screens, use motion.div
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.7 }}
            className="flex flex-col sm:flex-row items-center justify-center gap-6"
          >
            <p className="text-xl sm:text-2xl lg:text-3xl text-black">
              THOUGHTFULLY CRAFTED WEBSITES & APPS
            </p>
            <p className="text-xl sm:text-2xl lg:text-3xl text-black">
              BUILT FOR THE LONG HAUL
            </p>
          </motion.div>
        )}
      </div>

      {/* Grid for mobile */}
      <div className="grid gap-10 mt-16 sm:grid-cols-2 lg:grid-cols-3 lg:hidden">
        {projectsData.map((project, index) => (
          <div
            key={index}
            className="rounded-lg overflow-hidden shadow-lg p-6 bg-white"
            style={{ backgroundColor: project.backgroundColor }}
          >
            <Link to={`/projectDetail/${project.id}`}>
              <div className="text-center">
                <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
                <p className="text-md mb-4">{project.description}</p>
                <img
                  src={project.profile}
                  alt={project.name}
                  className="w-16 h-16 rounded-full mx-auto mb-2"
                />
                <p className="italic">{project.comment}</p>
                <div className="mt-4 flex justify-center">
                  {[...Array(5)].map((_, i) => (
                    <IoIosStar key={i} size={20} color="yellow" />
                  ))}
                </div>
              </div>
              <div className="mt-6">
                <img
                  src={project.imageSrc}
                  alt={project.title}
                  className="w-full h-auto rounded-md"
                />
              </div>
            </Link>
          </div>
        ))}
      </div>

      {/* Original design for large screens */}
      <div className="hidden lg:block projects-featured" data-parallax>
        <div className="projects-featured__item">
          <div className="projects-featured__item-inner ">
            <div className="projects-featured__item-info-2 ">
              <div
                id="services"
                className="sm:flex sm:justify-center bg-white h-screen w-full relative"
              >
                <div className="text-center sm:text-left mx-auto">
                  <h2 className="block pt-4 sm:px-3 md:px-4 sm:pt-7 md:pt-4 lg:px-6 lg:p-2 text-black font-semibold">
                    <span className="inline-block overflow-hidden text-black">
                      <span className="font-poppins font-bold text-6xl leading-[60px] sm:text-7xl md:text-8xl lg:text-9xl">
                        INNOVATIVE APP AND
                      </span>
                    </span>
                    <span className="inline-block overflow-hidden text-black">
                      <span className="font-poppins font-bold text-6xl leading-[60px] sm:text-7xl md:text-8xl lg:text-9xl">
                        WEB SOLUTIONS
                      </span>
                    </span>
                  </h2>
                  <motion.div
                    ref={ref}
                    variants={{
                      hidden: { opacity: 0, y: 70 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                    transition={{
                      staggerChildren: 0.1,
                      duration: 0.5,
                      delay: 0.5,
                    }}
                    className="md:flex md:items-center mt-10 sm:mt-20 md:h-[100px] sm:px-4 sm:p-1 lg:px-6 lg:p-2"
                  >
                    <p className="text-2xl sm:text-2xl lg:text-4xl leading-120 text-black md:mr-20 mb-10 md:mb-0">
                      THOUGHTFULLY <br /> CRAFTED WEBSITES & APPS
                    </p>
                    <p className="text-2xl sm:text-2xl lg:text-4xl leading-120 text-black">
                      BUILT FOR <br />
                      THE LONG HAUL
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {projectsData.map((project, index) => (
            <div
              key={index}
              className="projects-featured__item"
              style={{ backgroundColor: project.backgroundColor }}
            >
              <div className="projects-featured__item-inner">
                <div className="projects-featured__item-info">
                  <Link to={`/projectDetail/${project.id}`}>
                    <div className="titleWrapper">
                      <h3 className="title">{project.title}</h3>
                      <p className="description">{project.description}</p>
                    </div>
                    <div className="user">
                      <img src={project.profile} alt="" />
                      <p>{project.comment}</p>
                      <div className="nameIcon">
                        <h3>{project.name}</h3>
                        <div style={{ display: "flex" }}>
                          <IoIosStar size={14} color="yellow" />
                          <IoIosStar size={14} color="yellow" />
                          <IoIosStar size={14} color="yellow" />
                          <IoIosStar size={14} color="yellow" />
                          <IoIosStar size={14} color="yellow" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="projects-featured__item-img">
                  <a data-cursor="view">
                    <img
                      width="1900"
                      height="1100"
                      src={project.imageSrc}
                      className="attachment-Section Huge size-Section Huge wp-post-image"
                      fetchpriority="high"
                      sizes="(max-width: 1900px) 90vw, 1900px"
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
