import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { useMediaQuery } from "usehooks-ts";
import Email from "../../home/contactUs/img/email.png";
import Location from "../../home/contactUs/img/location.png";
import Phone from "../../home/contactUs/img/phone.png";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [details, setDetails] = useState("");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const sendEmail = (e) => {
    e.preventDefault();

    //your emailjs service id, template id, public id
    const serviceId = "service_ahfyvba";
    const templateId = "template_bzhdzhj";
    const publicId = "k7Mz7Z0VIw2q4_VlQ";

    // create new object that create dynamic template program
    if (!name || !phone || isNaN(phone) || !email || !details)
      return alert("Fill valid details");
    const templateParams = {
      from_name: name,
      from_email: email,
      name: name,
      phone: phone,
      email: email,
      details: details,
    };

    //send the email using emailjs
    emailjs
      .send(serviceId, templateId, templateParams, publicId)
      .then((response) => {
        console.log("email sent successfully!", response);
        setName("");
        setEmail("");
        setDetails("");
        setPhone("");
        alert("Request Raised!");
      })
      .catch((error) => {
        console.error("error sending email:", error);
      });
  };

  return (
    <div className="min-h-screen bg-black mt-20 sm:mt-24 pt-12 ">
      <div className=" text-center md:text-left px-4 sm:px-12 pt-12 sm:pt-2">
        <span className="font-poppins font-bold text-white text-6xl leading-[60px] sm:text-8xl md:text-8xl lg:text-9xl">
          CONTACT US
        </span>
        <p className="w-full md:w-2/3 text-white font-text font-bold text-lg sm:text-sm md:text-base lg:text-2xl mt-7 md:mt-20">
          Welcome to Cosmolink, your ultimate destination for top-notch app and
          web development solutions. As a dynamic and innovative agency, we
          specialize in turning your visionary concepts into seamless digital
          experiences.
        </p>
      </div>
      <div className="flex flex-col md:flex-row md:justify-around text-center items-center md:items-start py-10 sm:py-20 ">
        <div className="relative md:w-[50%] w-[90%]">
          <form className="relative z-10 overflow-hidden p-2 md:p-6 lg:p-0 sm:p-2 h-full">
            <div className="md:mb-3 sm:mb-2 mb-2 ">
              <input
                type="text"
                name="name"
                className="font-text p-[0.3rem] md:p-[0.4rem] rounded-xl w-[100%] border-2 border-solid bg-black text-white placeholder:text-white"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="md:mb-3 sm:mb-2 mb-2">
              <input
                type="email"
                name="email"
                className="font-text p-[0.3rem] md:p-[0.4rem] rounded-xl w-[100%] border-2 border-solid bg-black text-white placeholder:text-white"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="md:mb-3 sm:mb-2 mb-2">
              <input
                type="tel"
                name="phone"
                className="font-text p-[0.3rem] md:p-[0.4rem] rounded-xl w-[100%] border-2 border-solid bg-black text-white placeholder:text-white"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="md:mb-3 sm:mb-2 mb-2">
              <textarea
                name="message"
                className="font-text pl-2 pt-2 rounded-xl h-32 w-[100%] lg:h-40 md:h-20 border-2 border-solid bg-black text-white placeholder:text-white resize-none "
                placeholder="Message"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              ></textarea>
            </div>
            <input
              type="submit"
              value="Send"
              className="btn bg-white font-text text-black font-bold px-4 py-1 md:px-5 md:py-2 rounded-full text-top"
              onClick={sendEmail}
            />
          </form>
        </div>

        <div className=" flex flex-col gap-4 p-5 mt-10 text-center sm:mt-1 md:w-1/4 ">
          <p className="font-text md:text-xl text-white text-base mb-1 md:mb-3 w-[100%] ">
            Manoram Business Center, Near Shyam Nagar Metro Station, Jaipur
          </p>

          <p className="font-text md:text-xl text-base text-white mb-1 md:mb-3 ">
            contact@cosmolink.com
          </p>
          <p className="font-text md:text-xl text-base text-white ">
            +918949605284
          </p>
        </div>
        <div className="mt:2 flex flex-row md:flex-col p-5  ">
          <p className="text-white font-text md:text-xl text-base mt-1 mr-1">
            Connect with us:
          </p>
          <div className="flex space-x-2 mt-2">
            {/* <a href="#" className="text-white hover:text-gray-300">
              <FaFacebook size={isMobile ? 18 : 25} />
            </a>
            <a href="#" className="text-white hover:text-gray-300">
              <FaTwitter size={isMobile ? 18 : 25} />
            </a>
            <a href="#" className="text-white hover:text-gray-300">
              <FaInstagram size={isMobile ? 18 : 25} />
            </a> */}
            <a href="#" className="text-white hover:text-gray-300">
              <FaLinkedin size={isMobile ? 18 : 25} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
